<template>
  <div>
    <el-container>
      <el-col>
        <div
          v-for="(field, fieldIndex) in fields"
          :key="new Date().getTime() + fieldIndex"
          class="info-modal-item"
        >
          <template v-if="field === 'products'">
            <span class="whi"><b>Товары</b>:</span>

            <div
              v-for="(product, productIndex) in salesFullData.productData"
              :key="new Date().getTime() + productIndex"
              class="products-list"
            >
              <span><b>{{ product.title }}</b>:&nbsp;</span>
              <span v-if="isSalePriceInProduct(product)">Скидка - {{ product.salePrice }} р.</span>
              <span v-else>{{ product.price }} р.</span>
            </div>
          </template>

          <template v-if="field === 'productsNames'">
            <span class="whi"><b>Товары</b>:</span>

            <div
              v-for="(product, productIndex) in fieldsOptions[field].value"
              :key="new Date().getTime() + productIndex"
              class="products-list"
            >
              <span>{{ product }}</span>
            </div>
          </template>

          <template v-else>
            <span><b>{{ propsData.fields[field].name }}</b>:&nbsp;</span>
            <span
              style="white-space: break-spaces;"
            >{{ fieldsOptions[field].value }}</span>
          </template>
        </div>
      </el-col>
    </el-container>

    <div class="block-buttons block-buttons__right">
      <el-button
        class="el-button-h-40"
        type="warning"
        plain
        @click="closeModal(null)"
      >
        Закрыть
      </el-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { isSalePriceInProduct } from '@/helper'

export default {
  name: 'info-modal',
  props: {
    propsData: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      fields: Object.keys(this.propsData.fields),
      fieldsOptions: this.propsData.fields || {},
      salesFullData: {}
    }
  },
  computed: {
    ...mapGetters('categories', { categories: 'getData' }),
    title () {
      return ['sales', 'salesUsers'].includes(this.propsData.page)
        ? `Продажа за ${this.fieldsOptions.createdAt.value}`
        : this.propsData?.item?.title
        || ''
    }
  },
  async created () {
    if (['sales', 'salesUsers'].includes(this.propsData.page)) {
      this.salesFullData = await this.getDataById(this.propsData?.item.id)
    }
  },
  methods: {
    ...mapActions('modal', ['closeModal']),
    ...mapActions('sales', ['getDataById']),
    isSalePriceInProduct
  }
}
</script>

<style lang="scss" src="./style.scss" />
